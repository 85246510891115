import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import { GAME_OVER } from "../constants/status.js";

function Status(props) {
  const gameContext = useContext(GameContext);
  const { bet, payout, credits, status } = gameContext;

  return (
    <div className="col-span-3 bg-yellow-300 rounded">
      {status === GAME_OVER ? (
        <div className="flex justify-center text-red-600 font-digital text-3xl pt-8">
          <div className="">GAME OVER</div>
        </div>
      ) : (
        <div className="grid grid-cols-3 gap-3 p-3 pt-6 font-lato_bold text-green-800">
          <div className="flex flex-col text-center">
            <div className="text-lg mb-1">BET</div>
            <div className="text-red-600 font-digital text-3xl">{bet}</div>
          </div>
          <div className="flex flex-col text-center">
            <div className="text-lg mb-1">WIN</div>
            <div className="text-red-600 font-digital text-3xl">{payout}</div>
          </div>
          <div className="flex flex-col text-center">
            <div className="text-lg mb-1">SCORE</div>
            <div className="text-red-600 font-digital text-3xl">{credits}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Status;
