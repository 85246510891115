import React from "react";
import logo from "../images/bh-logo-grey.gif";

function Small() {
  return (
    <div>
      <div className="flex w-full justify-center">
        <div className="flex w-3/4 flex flex-col justify-center col-span-4 bg-green-600 text-white mx-auto mt-12 py-16">
          <div className="text-sm text-center">JACKS OR BETTER</div>
          <div className="text-5xl text-center font-chunk leading-none">
            Poker
          </div>
        </div>
      </div>
      <div className="w-full text-center text-xl px-6 py-16">
        This application requires a wider screen width.
      </div>
      <footer className="flex w-full justify-center bg-warm-gray-200 py-4">
        <div className="w-16">
          <a href="https://bob-humphrey.com">
            <img src={logo} alt="Logo" />
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Small;
