import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import { payoutTable } from "../functions/getPayout";

function PayoutTable(props) {
  const gameContext = useContext(GameContext);
  const { bet, rank, payout, payTableVisibility } = gameContext;
  const rankPanelVisibility = payTableVisibility === "hidden" ? "" : "hidden";
  const rankTable = [];
  const bet1Table = [];
  Object.keys(payoutTable).forEach(key => {
    const value = payoutTable[key];
    rankTable.push(key);
    bet1Table.push(value);
  });
  const rankDisplay = rank ? rank : "NONE";
  const rankColor = rank ? "text-green-700" : "text-white";
  return (
    <div>
      <div
        className={
          payTableVisibility + " border-2 border-green-600 rounded p-2 mb-2"
        }
      >
        <div className="grid grid-cols-12 gap-2 font-lato_bold">
          <div className="col-span-3">
            {rankTable.map(function(tblRank, index) {
              const color = tblRank === rank ? "bg-red-600 text-white" : "";
              return <div className={color}>{tblRank}</div>;
            })}
          </div>
          <div className="">
            {bet1Table.map(function(amount, index) {
              const regularColor = bet === 1 ? "bg-yellow-300 text-red" : "";
              const color =
                amount === payout && bet === 1
                  ? "bg-red-600 text-white"
                  : regularColor;
              return <div className={color + " text-center"}>{amount}</div>;
            })}
          </div>
          <div className="">
            {bet1Table.map(function(amount, index) {
              const regularColor = bet === 2 ? "bg-yellow-300 text-red" : "";
              const color =
                amount * 2 === payout && bet === 2
                  ? "bg-red-600 text-white"
                  : regularColor;
              return <div className={color + " text-center"}>{amount * 2}</div>;
            })}
          </div>
          <div className="">
            {bet1Table.map(function(amount, index) {
              const regularColor = bet === 3 ? "bg-yellow-300 text-red" : "";
              const color =
                amount * 3 === payout && bet === 3
                  ? "bg-red-600 text-white"
                  : regularColor;
              return <div className={color + " text-center"}>{amount * 3}</div>;
            })}
          </div>
          <div className="">
            {bet1Table.map(function(amount, index) {
              const regularColor = bet === 4 ? "bg-yellow-300 text-red" : "";
              const color =
                amount * 4 === payout && bet === 4
                  ? "bg-red-600 text-white"
                  : regularColor;
              return <div className={color + " text-center"}>{amount * 4}</div>;
            })}
          </div>
          <div className="">
            {bet1Table.map(function(amount, index) {
              const regularColor = bet === 5 ? "bg-yellow-300 text-red" : "";
              const color =
                amount * 5 === payout && bet === 5
                  ? "bg-red-600 text-white"
                  : regularColor;
              return <div className={color + " text-center"}>{amount * 5}</div>;
            })}
          </div>
          <div className="flex flex-col justify-center col-span-4 bg-green-600 text-white ">
            <div className="text-sm text-center">JACKS OR BETTER</div>
            <div className="text-5xl text-center font-chunk leading-none">
              Poker
            </div>
          </div>
        </div>
      </div>
      <div className={rankPanelVisibility}>
        <div className={rankColor + " w-full text-center text-2xl"}>
          {rankDisplay}
        </div>
      </div>
    </div>
  );
}

export default PayoutTable;
