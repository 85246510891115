export const payoutTable = {
  "ROYAL FLUSH": 250,
  "STRAIGHT FLUSH": 50,
  "FOUR OF A KIND": 25,
  "FULL HOUSE": 9,
  FLUSH: 6,
  STRAIGHT: 4,
  "THREE OF A KIND": 3,
  "TWO PAIR": 2,
  "JACKS OR BETTER": 1
};

function getPayout(rank, bet) {
  if (!rank) {
    return 0;
  } else {
    const payout = payoutTable[rank] * bet;
    return payout;
  }
}

export default getPayout;
