import React from "react";
import "./css/tailwind.css";
import { GameProvider } from "./contexts/GameContext";
import Small from "./components/Small";
import PayoutTable from "./components/PayoutTable";
import Hand from "./components/Hand";
import Controls from "./components/Controls";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <div className="md:hidden">
        <Small />
      </div>
      <div className="hidden md:block w-5/6 lg:w-2/3 xl:w-1/2 mx-auto pt-4">
        <GameProvider>
          <PayoutTable />
          <Hand />
          <Controls />
          <Footer />
        </GameProvider>
      </div>
    </div>
  );
}

export default App;
