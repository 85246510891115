import React, { useState } from "react";
import getDeck from "../functions/getDeck";
import determineHandRank from "../functions/determineHandRank";
import getPayout from "../functions/getPayout";
import { START, DISCARD, DRAW, GAME_OVER } from "../constants/status.js";

const GameContext = React.createContext();
const STARTING_CREDITS = 100;

const GameProvider = props => {
  const [credits, setCredits] = useState(STARTING_CREDITS);
  const [bet, setBet] = useState(1);
  const [canIncreaseBet, setCanIncreaseBet] = useState(true);
  const [payout, setPayout] = useState(0);
  const [deck, setDeck] = useState("");
  const [hand, setHand] = useState([]);
  const [discards, setDiscards] = useState([]);
  const [status, setStatus] = useState(START);
  const [rank, setRank] = useState(null);
  const [uniqueString, setUniqueString] = useState(null);
  const [payTableVisibility, setPayTableVisibility] = useState("");

  const drawFromDeck = (deck, numberOfCards) => {
    const draw = [];
    for (let index = 0; index < numberOfCards; index++) {
      const card = deck.shift();
      draw.push(card);
    }
    const drawFromDeckResults = {
      deck: deck,
      draw: draw
    };
    return drawFromDeckResults;
  };

  const getUniqueString = () => {
    let uniqueString =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    return uniqueString;
  };

  const handleDeal = () => {
    // If previous game is over, reinitialize and start new game.
    if (status === GAME_OVER) {
      setCredits(STARTING_CREDITS);
      setBet(1);
      setCanIncreaseBet(true);
      setPayout(0);
      setHand([]);
      setDiscards([]);
      setRank(null);
      setStatus(START);
    } else if (status === START) {
      // Get a new hand and update the deck.
      let newDeck = getDeck();
      const drawFromDeckResults = drawFromDeck(newDeck, 5);
      const newRank = determineHandRank(drawFromDeckResults.draw);
      setHand(drawFromDeckResults.draw);
      setRank(newRank);
      let updatedDiscards = [];
      for (let index = 0; index < 5; index++) {
        updatedDiscards.push("Discard");
      }
      setDiscards(updatedDiscards);
      setDeck(drawFromDeckResults.deck);
      // Adjust the bet and credits;
      let updatedCredits;
      let updatedBet;
      if (bet > credits) {
        updatedBet = credits;
        setBet(updatedBet);
        setCredits(0);
      } else {
        updatedCredits = credits - bet;
        setCredits(updatedCredits);
      }
      setPayout(0);
      setStatus(DISCARD);
    }
  };

  const handleDiscards = clickEvent => {
    if (status === START) return;
    if (status === GAME_OVER) return;
    const cardNumber = clickEvent.target.value;
    let updatedDiscards = discards;
    const updatedDiscard =
      updatedDiscards[cardNumber] === "Discard" ? "Keep" : "Discard";
    updatedDiscards[cardNumber] = updatedDiscard;
    setDiscards(updatedDiscards);
    setUniqueString(getUniqueString());
    setStatus(DRAW);
  };

  const handleDraw = () => {
    if (status === START) return;
    if (status === GAME_OVER) return;
    // Update the hand.
    let updatedHand = hand;
    let updatedDiscards = [];
    let currentDeck = deck;
    for (let index = 0; index < 5; index++) {
      const discard = discards[index];
      if (discard === "Keep") {
        let drawFromDeckResults = drawFromDeck(currentDeck, 1);
        let card = drawFromDeckResults.draw[0];
        updatedHand[index] = card;
        currentDeck = drawFromDeckResults.deck;
      }
      updatedDiscards.push("Discard");
    }
    // Update the score.
    const updatedRank = determineHandRank(updatedHand);
    const updatedPayout = getPayout(updatedRank, bet);
    const updatedCredits = credits + updatedPayout;
    let updatedBet = bet;
    // Evaluate bet status.
    let updatedCanIncreaseBet = canIncreaseBet;
    if (bet >= updatedCredits) {
      updatedBet = updatedCredits;
      updatedCanIncreaseBet = false;
    }
    // Update the state.
    setPayout(updatedPayout);
    setHand(updatedHand);
    setDiscards(updatedDiscards);
    setRank(updatedRank);
    setCredits(updatedCredits);
    setBet(updatedBet);
    setCanIncreaseBet(updatedCanIncreaseBet);
    setDeck(currentDeck);
    // Is the game over?
    if (updatedCredits === 0) {
      setStatus(GAME_OVER);
    } else {
      setStatus(START);
    }
  };

  const increaseBet = () => {
    if (status === DISCARD) return;
    if (status === DRAW) return;
    if (status === GAME_OVER) return;
    if (bet === 5) return;
    if (bet === credits) return;
    const updatedBet = bet + 1;
    // Evaluate bet status.
    let updatedCanIncreaseBet = canIncreaseBet;
    if (updatedBet >= credits) {
      updatedCanIncreaseBet = false;
    }
    setCanIncreaseBet(updatedCanIncreaseBet);
    setBet(updatedBet);
  };

  const decreaseBet = () => {
    if (status === DISCARD) return;
    if (status === DRAW) return;
    if (status === GAME_OVER) return;
    if (bet === 1) return;
    const updatedBet = bet - 1;
    // Evaluate bet status.
    let updatedCanIncreaseBet = canIncreaseBet;
    if (updatedBet < credits) {
      updatedCanIncreaseBet = true;
    }
    setCanIncreaseBet(updatedCanIncreaseBet);
    setBet(updatedBet);
  };

  const togglePayTableVisibility = () => {
    if (status === GAME_OVER) return;
    const updatedVisibility = payTableVisibility === "hidden" ? "" : "hidden";
    setPayTableVisibility(updatedVisibility);
  };

  return (
    <GameContext.Provider
      value={{
        credits,
        bet,
        canIncreaseBet,
        payout,
        deck,
        hand,
        discards,
        status,
        rank,
        uniqueString,
        handleDeal,
        handleDiscards,
        handleDraw,
        increaseBet,
        decreaseBet,
        payTableVisibility,
        togglePayTableVisibility
      }}
    >
      {props.children}
    </GameContext.Provider>
  );
};

export { GameContext, GameProvider };
