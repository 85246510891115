import {
  ROYAL_FLUSH,
  STRAIGHT_FLUSH,
  FOUR_OF_A_KIND,
  FULL_HOUSE,
  FLUSH,
  STRAIGHT,
  THREE_OF_A_KIND,
  TWO_PAIR,
  JACKS_OR_BETTER
} from "../constants/rank.js";

function determineHandRank(hand) {
  let cardValues = {
    ACE: 0,
    "1": 0,
    "2": 0,
    "3": 0,
    "4": 0,
    "5": 0,
    "6": 0,
    "7": 0,
    "8": 0,
    "9": 0,
    "10": 0,
    JACK: 0,
    QUEEN: 0,
    KING: 0
  };

  let cardCounts = {
    singles: 0,
    pairs: 0,
    threeOfAKind: 0,
    fourOfAKind: 0
  };

  let flush = false;
  let straight = false;
  let royal = false;
  let jacksOrBetter = false;

  hand.map(function(card, index) {
    cardValues[card.value]++;
    return null;
  });

  Object.keys(cardValues).forEach(key => {
    let value = cardValues[key];
    if (value === 1) {
      cardCounts.singles++;
    } else if (value === 2) {
      cardCounts.pairs++;
    } else if (value === 3) {
      cardCounts.threeOfAKind++;
    } else if (value === 4) {
      cardCounts.fourOfAKind++;
    }
  });

  // Determine flush hand.
  if (
    hand[0].suit === hand[1].suit &&
    hand[1].suit === hand[2].suit &&
    hand[2].suit === hand[3].suit &&
    hand[3].suit === hand[4].suit
  ) {
    flush = true;
  }

  // Determine straight hand.
  if (
    cardValues.ACE &&
    cardValues["1"] &&
    cardValues["2"] &&
    cardValues["3"] &&
    cardValues["4"]
  ) {
    straight = true;
  } else if (
    cardValues["1"] &&
    cardValues["2"] &&
    cardValues["3"] &&
    cardValues["4"] &&
    cardValues["5"]
  ) {
    straight = true;
  } else if (
    cardValues["2"] &&
    cardValues["3"] &&
    cardValues["4"] &&
    cardValues["5"] &&
    cardValues["6"]
  ) {
    straight = true;
  } else if (
    cardValues["3"] &&
    cardValues["4"] &&
    cardValues["5"] &&
    cardValues["6"] &&
    cardValues["7"]
  ) {
    straight = true;
  } else if (
    cardValues["4"] &&
    cardValues["5"] &&
    cardValues["6"] &&
    cardValues["7"] &&
    cardValues["8"]
  ) {
    straight = true;
  } else if (
    cardValues["5"] &&
    cardValues["6"] &&
    cardValues["7"] &&
    cardValues["8"] &&
    cardValues["9"]
  ) {
    straight = true;
  } else if (
    cardValues["6"] &&
    cardValues["7"] &&
    cardValues["8"] &&
    cardValues["9"] &&
    cardValues["10"]
  ) {
    straight = true;
  } else if (
    cardValues["7"] &&
    cardValues["8"] &&
    cardValues["9"] &&
    cardValues["10"] &&
    cardValues.JACK
  ) {
    straight = true;
  } else if (
    cardValues["8"] &&
    cardValues["9"] &&
    cardValues["10"] &&
    cardValues.JACK &&
    cardValues.QUEEN
  ) {
    straight = true;
  } else if (
    cardValues["9"] &&
    cardValues["10"] &&
    cardValues.JACK &&
    cardValues.QUEEN &&
    cardValues.KING
  ) {
    straight = true;
  } else if (
    cardValues["10"] &&
    cardValues.JACK &&
    cardValues.QUEEN &&
    cardValues.KING &&
    cardValues.ACE
  ) {
    straight = true;
  }

  // Determine royal hand.
  if (
    cardValues["10"] &&
    cardValues.JACK &&
    cardValues.QUEEN &&
    cardValues.KING &&
    cardValues.ACE
  ) {
    royal = true;
  }

  // Determine jacks or better.
  if (
    cardValues.ACE === 2 ||
    cardValues.JACK === 2 ||
    cardValues.QUEEN === 2 ||
    cardValues.KING === 2
  ) {
    jacksOrBetter = true;
  }

  // Determine rank.
  if (royal && flush) {
    return ROYAL_FLUSH;
  } else if (straight && flush) {
    return STRAIGHT_FLUSH;
  } else if (cardCounts.fourOfAKind) {
    return FOUR_OF_A_KIND;
  } else if (cardCounts.threeOfAKind && cardCounts.pairs) {
    return FULL_HOUSE;
  } else if (flush) {
    return FLUSH;
  } else if (straight) {
    return STRAIGHT;
  } else if (cardCounts.threeOfAKind) {
    return THREE_OF_A_KIND;
  } else if (cardCounts.pairs === 2) {
    return TWO_PAIR;
  } else if (jacksOrBetter) {
    return JACKS_OR_BETTER;
  } else return null;
}

export default determineHandRank;
