import React from "react";
import Card from "./Card";

function Hand() {
  return (
    <div className="mb-2">
      <div className="grid grid-cols-5 gap-3">
        <Card number="0" key="0" />
        <Card number="1" key="1" />
        <Card number="2" key="2" />
        <Card number="3" key="3" />
        <Card number="4" key="4" />
      </div>
    </div>
  );
}

export default Hand;
