import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import { START, DISCARD, DRAW, GAME_OVER } from "../constants/status.js";
import Status from "./Status.js";

function Controls() {
  const gameContext = useContext(GameContext);
  const {
    status,
    bet,
    handleDeal,
    handleDraw,
    increaseBet,
    decreaseBet,
    canIncreaseBet,
    payTableVisibility,
    togglePayTableVisibility
  } = gameContext;
  const active = "bg-green-600 cursor-pointer";
  const inactive = "bg-gray-200 cursor-default";
  const gameOver = "bg-red-600 cursor-pointer";
  let dealStatus;
  let drawStatus;
  let keepAllStatus;
  let togglePayTableStatus;
  switch (status) {
    case START:
      dealStatus = active;
      drawStatus = inactive;
      keepAllStatus = inactive;
      togglePayTableStatus = active;
      break;
    case DISCARD:
      dealStatus = inactive;
      drawStatus = inactive;
      keepAllStatus = active;
      togglePayTableStatus = active;
      break;
    case DRAW:
      dealStatus = inactive;
      drawStatus = active;
      keepAllStatus = inactive;
      togglePayTableStatus = active;
      break;
    case GAME_OVER:
      dealStatus = gameOver;
      drawStatus = inactive;
      keepAllStatus = inactive;
      togglePayTableStatus = inactive;
      break;
    default:
      break;
  }
  const increaseBetStatus =
    bet === 5 || status !== START || !canIncreaseBet ? inactive : active;
  const decreaseBetStatus = bet === 1 || status !== START ? inactive : active;
  const payTableButtonLabel =
    payTableVisibility === "hidden" ? "Show Pay Tbl" : "Hide Pay Tbl";
  const dealLabel = status === GAME_OVER ? "Play Again" : "Deal";

  return (
    <div>
      <div className="font-lato_bold border-2 border-green-600 rounded p-2">
        <div className="grid grid-cols-6 gap-2">
          <div className="flex flex-col">
            <button
              className={
                dealStatus +
                " text-white font-lato_bold py-6 focus:outline-none rounded mb-2"
              }
              onClick={() => handleDeal()}
            >
              {dealLabel}
            </button>
            <button
              className={
                decreaseBetStatus +
                " text-white font-lato_bold py-2 focus:outline-none rounded"
              }
              onClick={() => decreaseBet()}
            >
              Bet -
            </button>
          </div>
          <div className="flex flex-col">
            <button
              className={
                drawStatus +
                " text-white font-lato_bold py-6 focus:outline-none rounded mb-2"
              }
              onClick={() => handleDraw()}
            >
              Draw
            </button>
            <button
              className={
                increaseBetStatus +
                " text-white font-lato_bold py-2 focus:outline-none rounded"
              }
              onClick={() => increaseBet()}
            >
              Bet +
            </button>
          </div>
          <div className="flex flex-col">
            <button
              className={
                keepAllStatus +
                " text-white font-lato_bold py-6 focus:outline-none rounded mb-2"
              }
              onClick={() => handleDraw()}
            >
              Keep All
            </button>
            <button
              className={
                togglePayTableStatus +
                " text-white font-lato_bold py-2 focus:outline-none rounded"
              }
              onClick={() => togglePayTableVisibility()}
            >
              {payTableButtonLabel}
            </button>
          </div>
          <Status />
        </div>
      </div>
    </div>
  );
}

export default Controls;
