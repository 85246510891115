import React, { useContext } from "react";
import { GameContext } from "../contexts/GameContext";
import { START, DISCARD, DRAW, GAME_OVER } from "../constants/status.js";
import blank from "../images/blank.png";

function Card(props) {
  const gameContext = useContext(GameContext);
  const { hand, discards, status, handleDiscards } = gameContext;
  const show = hand.length ? "" : "invisible";
  const buttonDisplay = discards[props.number]
    ? discards[props.number]
    : "Poker";
  const active = "bg-green-600 cursor-pointer";
  const inactive = "bg-gray-200 cursor-default";
  let buttonStatus;
  switch (status) {
    case START:
    case GAME_OVER:
      buttonStatus = inactive;
      break;
    case DRAW:
    case DISCARD:
      buttonStatus = active;
      break;
    default:
      break;
  }

  let opacity = "";
  if (hand.length) {
    if (discards[props.number] === "Keep") {
      opacity = "opacity-25";
    }
  }
  const imgSrc = hand.length
    ? "https://images.bob-humphrey.com/cards/" +
      hand[props.number].code +
      ".png"
    : blank;
  return (
    <div>
      <div className="">
        <img className={opacity} src={imgSrc} alt="Playing card" />
        <button
          className={
            show +
            " " +
            buttonStatus +
            " w-full text-white text-center font-lato_bold py-2 mt-2 focus:outline-none rounded"
          }
          onClick={handleDiscards}
          value={props.number}
          name="card"
        >
          {buttonDisplay}
        </button>
      </div>
    </div>
  );
}

export default Card;
