function getDeck() {
  const codes = [
    "AS",
    "2S",
    "3S",
    "4S",
    "5S",
    "6S",
    "7S",
    "8S",
    "9S",
    "0S",
    "JS",
    "QS",
    "KS",
    "AD",
    "2D",
    "3D",
    "4D",
    "5D",
    "6D",
    "7D",
    "8D",
    "9D",
    "0D",
    "JD",
    "QD",
    "KD",
    "AC",
    "2C",
    "3C",
    "4C",
    "5C",
    "6C",
    "7C",
    "8C",
    "9C",
    "0C",
    "JC",
    "QC",
    "KC",
    "AH",
    "2H",
    "3H",
    "4H",
    "5H",
    "6H",
    "7H",
    "8H",
    "9H",
    "0H",
    "JH",
    "QH",
    "KH"
  ];

  const suits = {
    S: "SPADES",
    D: "DIAMONDS",
    H: "HEARTS",
    C: "CLUBS"
  };
  const values = {
    A: "ACE",
    J: "JACK",
    Q: "QUEEN",
    K: "KING",
    "0": "10",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9"
  };

  let deck = [];

  codes.map(function(code, index) {
    const suitCode = code.substr(1);
    const valueCode = code.substr(0, 1);
    deck.push({ value: values[valueCode], suit: suits[suitCode], code: code });
    return null;
  });

  // Shuffle the deck.
  for (let i = deck.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = deck[i];
    deck[i] = deck[j];
    deck[j] = temp;
  }

  return deck;
}

export default getDeck;
